import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
<path d="M1280 1894 c-145 -31 -283 -107 -393 -217 -58 -58 -58 -59 -40 -79
30 -33 192 -105 298 -132 115 -29 270 -47 389 -45 l81 2 -95 8 c-94 9 -231 33
-270 47 -19 7 -19 9 12 67 46 88 83 140 147 203 l56 55 -53 -28 c-67 -36 -157
-132 -200 -212 l-33 -62 -51 20 c-62 24 -158 80 -158 92 0 18 118 131 180 172
76 50 118 70 220 101 l75 23 -50 0 c-27 -1 -79 -7 -115 -15z"/>
<path d="M1653 1750 c2 -27 6 -30 35 -30 29 0 32 3 32 30 0 28 -3 30 -35 30
-32 0 -34 -2 -32 -30z"/>
<path d="M1910 1750 c0 -15 7 -20 25 -20 18 0 25 5 25 20 0 15 -7 20 -25 20
-18 0 -25 -5 -25 -20z"/>
<path d="M1500 1595 l0 -65 65 0 65 0 0 65 0 65 -65 0 -65 0 0 -65z"/>
<path d="M1720 1619 l0 -40 42 3 c36 2 43 6 42 23 -3 55 -3 55 -44 55 l-40 0
0 -41z"/>
<path d="M600 1593 c-73 -27 -62 -133 14 -133 42 0 64 17 72 55 9 48 -42 94
-86 78z"/>
<path d="M161 1546 l-33 -12 56 -1 c67 -2 143 -39 187 -93 17 -19 63 -104 104
-188 141 -290 237 -407 399 -486 167 -82 338 -116 571 -115 229 1 270 14 70
21 -290 12 -520 84 -647 202 -35 34 -58 136 -58 263 0 115 18 205 58 301 l29
68 -50 27 c-35 18 -54 24 -62 17 -21 -17 -74 -144 -95 -225 -11 -43 -20 -107
-20 -142 0 -34 -4 -63 -8 -63 -4 0 -36 57 -69 128 -85 176 -160 261 -263 297
-50 18 -122 18 -169 1z"/>
<path d="M1141 1403 c-18 -47 -49 -194 -56 -270 -6 -76 7 -208 28 -268 11 -32
12 -24 9 68 -5 128 9 243 47 379 22 76 26 105 18 110 -22 14 -34 8 -46 -19z"/>
<path d="M1900 1350 c-43 -10 -87 -58 -95 -103 -13 -67 9 -110 100 -200 74
-73 85 -87 85 -118 0 -36 -14 -59 -37 -59 -24 0 -53 39 -53 71 l0 32 -47 -7
c-25 -3 -48 -8 -50 -10 -7 -7 15 -87 32 -112 46 -70 194 -70 240 0 9 14 19 43
22 64 9 68 -13 110 -103 199 -74 72 -84 85 -84 117 0 60 64 74 76 17 11 -50
11 -50 51 -44 21 3 41 9 46 12 13 12 -23 110 -46 126 -29 19 -93 26 -137 15z"/>
<path d="M1326 1333 c-3 -10 -26 -128 -51 -263 -25 -135 -48 -251 -50 -257 -3
-9 11 -13 49 -13 l53 0 9 58 9 57 46 3 47 3 8 -58 9 -58 51 -3 51 -3 -48 253
c-27 139 -51 263 -54 276 -5 20 -11 22 -65 22 -47 0 -60 -3 -64 -17z m79 -205
c4 -35 10 -80 15 -100 l8 -38 -35 0 -36 0 7 48 c4 26 10 71 13 100 3 28 9 52
13 52 5 0 11 -28 15 -62z"/>
<path d="M1620 1075 l0 -275 55 0 55 0 0 275 0 275 -55 0 -55 0 0 -275z"/>
<path d="M770 754 c0 -5 24 -40 53 -79 93 -124 235 -225 384 -273 66 -22 243
-48 265 -39 7 3 1 6 -13 6 -46 2 -160 41 -243 84 -58 30 -104 65 -175 134 -78
76 -108 99 -161 120 -36 15 -75 33 -87 41 -13 8 -23 10 -23 6z"/>
<path d="M1718 673 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
